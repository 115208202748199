.contenedorEnProceso {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 20vh;
	text-align: center;
	height: 68.5vh;
}

.enProceso {
    font-size: 4rem;
	font-weight: 700;
	text-transform: uppercase;
	margin-bottom: 10px;
	margin-top: 40px;
	color: #fff;
}


.linkRegresarInicio {
	font-size: 1.2rem;
	background-color: #fff;
	border-radius: 5px;
	padding: 5px;
	color: #000 !important;
	text-decoration: none;
}

.linkRegresarInicio:hover {
    background-color: #888;
}